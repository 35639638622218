.filterCheck {
  display: none;
}

.filterCheck:checked + label:before {
content: "\2713";
display: inline-block;
width: 18px;
height: 18px;
line-height: 16px;
text-align: center;
background-color: #2ACFBB;
vertical-align: middle;
text-align: center;
color: #ffffff;
border-radius: 3px;
}

.filterCheck + label:before {
content: "";
display: inline-block;
vertical-align: middle;
width: 18px;
height: 18px;
border: 1px solid #2ACFBB;
margin-right: 10px;
border-radius: 3px;
}

.message-box {
  background-color: #2ACFBB;
  color: #ffff;
  padding: 10px;
  border-radius: 5px;
}
